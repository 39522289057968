import React, { Fragment,useState  } from 'react'
import OptionsTab from '../components/OptionsTab'
import OptionsPanel from '../components/OptionsPanel'
import Courses from '../components/OptionsTabs/Courses'; // Asegúrate de importar el componente correctamente

function Main() {
    const [option, setOption] = useState(null);

  return (
    <Fragment>
        <div className='row mt-5 mx-5'>   
            <div className='col-md-4'>   
                <OptionsPanel setOption ={setOption}/>
            </div>
            <div className='col-md-8'>   
                <OptionsTab option={option}/>
            </div>

        </div>

    </Fragment>
  )
}

export default Main
