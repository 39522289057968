import React, { useState, useEffect } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import { useConfig } from '../configContext';
import CourseModuleSessionModalCRUD from './CourseModuleSessionModalCRUD'; // Asegúrate de que la ruta sea correcta

const CourseModuleSesionModal = ({ show, handleClose, idCourse, idModule }) => {
  const config = useConfig();
  const [sessions, setSessions] = useState([]);
  const [showAddSessionModal, setShowAddSessionModal] = useState(false);

  const fetchSessions = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/CourseModuleSession/${idCourse}/${idModule}`);
      if (response.ok) {
        const data = await response.json();
        setSessions(data.data);
      } else {
        console.error('Failed to fetch sessions');
      }
    } catch (error) {
      console.error('Error fetching sessions', error);
    }
  };

  useEffect(() => {
    if (idModule && idCourse) {
      fetchSessions();
    }
  }, [idModule, idCourse, config.apiUrl]);

  const handleCloseAddSessionModal = () => {
    setShowAddSessionModal(false);
  };

  const handleOpenAddSessionModal = () => {
    setShowAddSessionModal(true); // Abrimos el modal de agregar sesión
  };

  const handleDeleteSession = async (idSession, video) => {
    const token = localStorage.getItem('token');

    if (window.confirm('¿Estás seguro de que deseas eliminar esta sesión?')) {
      try {
        const response = await fetch(`${config.apiUrl}/CourseModuleSession/delete`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ video, idSession }), // Enviamos el video y el idSession en el body
        });

        if (response.ok) {
          fetchSessions(); // Refrescamos la lista de sesiones
        } else {
          console.error('Error eliminando la sesión');
        }
      } catch (error) {
        console.error('Error eliminando la sesión', error);
      }
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Sesiones del Módulo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Orden</th>
                <th>Nombre</th>
                <th>Descripción</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {sessions.map((session) => (
                <tr key={session.idSession}>
                  <td>{session.number}</td>
                  <td>{session.name}</td>
                  <td>{session.description}</td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteSession(session.idSession, session.video)}
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleOpenAddSessionModal}>
            Agregar Sesión
          </Button>
        </Modal.Footer>
      </Modal>

      <CourseModuleSessionModalCRUD
        show={showAddSessionModal}
        handleClose={handleCloseAddSessionModal}
        idCourse={idCourse}
        idModule={idModule}
        onSessionAdded={() => fetchSessions()} // Refrescamos las sesiones cuando se agregue una
      />
    </>
  );
};

export default CourseModuleSesionModal;
