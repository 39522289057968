import React, { useState, useEffect } from 'react';
import { Modal, Button, ListGroup } from 'react-bootstrap';
import { useConfig } from '../configContext';

const AddCourseModal = ({ show, handleClose, idActor, idStudent, onCourseAdded }) => {
  const config = useConfig();
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/course/available/${idStudent}`);
        if (response.ok) {
          const data = await response.json();
          setCourses(data.data);
        } else {
          console.error('Failed to fetch courses');
        }
      } catch (error) {
        console.error('Error fetching courses', error);
      }
    };

    if (show) {
      fetchCourses();
    }
  }, [config.apiUrl, idStudent, show]);

  const handleSelectCourse = async (idCourse) => {
    try {
      const response = await fetch(`${config.apiUrl}/course/enroll`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idCourse, idStudent }),
      });

      if (response.ok) {
        onCourseAdded();  // Opcional: actualiza la vista principal si es necesario
        handleClose();
      } else {
        console.error('Failed to enroll student in course');
      }
    } catch (error) {
      console.error('Error enrolling student in course', error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar Curso</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          {courses.map((course) => (
            <ListGroup.Item
              key={course.id}
              action
              onClick={() => handleSelectCourse(course.id)}
            >
              {course.name}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCourseModal;
