import React, { useState, useEffect, Fragment } from 'react';
import { useConfig } from '../configContext';
import TeacherModal from '../course/TeacherModal';

function Teachers() {
  const config = useConfig();

  const [teachers, setTeachers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const fetchTeachers = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/teacher`);
      if (response.ok) {
        const data = await response.json();
        setTeachers(data.data);
      } else {
        console.error('Failed to fetch teachers');
      }
    } catch (error) {
      console.error('Error fetching teachers', error);
    }
  };

  useEffect(() => {

    fetchTeachers();
  }, [config.apiUrl]);

  const handleShowModal = (teacher = null) => {
    setSelectedTeacher(teacher);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTeacher(null);
  };

  const handleSaveTeacher = async (teacher) => {
    try {
      const endpoint = teacher.idTeacher ? '/teacher/Update' : '/teacher/Insert';
      const token = localStorage.getItem('token'); 

      const formData = new FormData();
      formData.append('fullName', teacher.fullName);
      formData.append('title', teacher.title);
      formData.append('img', teacher.img);
      formData.append('idTeacher', teacher.idTeacher);
      if (teacher.imgFile) {
        formData.append('imgFile', teacher.imgFile);
      }

      const response = await fetch(`${config.apiUrl}${endpoint}`, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}` 
        }
      });

      if (response.ok) {
        const data = await response.json();
        fetchTeachers();

        setShowModal(false);
      } else {
        console.error('Failed to save teacher');
      }
    } catch (error) {
      console.error('Error saving teacher', error);
    }
  };

  return (
    <Fragment>
      <button type="button" className="btn btn-primary" onClick={() => handleShowModal()}>Agregar nuevo profesor</button>
      <table className="table w-100">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Nombre Completo</th>
            <th scope="col">Título</th>
            <th scope="col">Imagen</th>
            <th scope="col">Editar</th>
            <th scope="col">Eliminar</th>
          </tr>
        </thead>
        <tbody>
          {teachers.map((teacher) => (
            <tr key={teacher.idTeacher}>
              <th scope="row">{teacher.idTeacher}</th>
              <td>{teacher.fullName}</td>
              <td>{teacher.title}</td>
              <td><img src={teacher.img} alt={teacher.fullName} width="50" height="50" /></td>
              <td><button onClick={() => handleShowModal(teacher)}>Edit</button></td>
              <td><button>Delete</button></td>
            </tr>
          ))}
        </tbody>
      </table>
      <TeacherModal
        show={showModal}
        handleClose={handleCloseModal}
        handleSave={handleSaveTeacher}
        teacher={selectedTeacher}
      />
    </Fragment>
  );
}

export default Teachers;
