import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const TeacherModal = ({ show, handleClose, handleSave, teacher }) => {
  const [formData, setFormData] = useState({
    idTeacher: '',
    fullName: '',
    title: '',
    img: '',
    imgFile: null,
  });

  useEffect(() => {
    if (teacher) {
      setFormData({
        idTeacher: teacher.idTeacher || '',
        fullName: teacher.fullName || '',
        title: teacher.title || '',
        img: teacher.img || '',
        imgFile: null,
      });
    } else {
      setFormData({
        idTeacher: '',
        fullName: '',
        title: '',
        img: '',
        imgFile: null,
      });
    }
  }, [teacher]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      imgFile: e.target.files[0],
    });
  };

  const handleSubmit = () => {
    const data = {
      idTeacher: formData.idTeacher,
      fullName: formData.fullName,
      title: formData.title,
      img: formData.img,
      imgFile: formData.imgFile,
    };

    handleSave(data);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{teacher ? 'Editar Profesor' : 'Agregar Profesor'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formFullName">
            <Form.Label>Nombre Completo</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nombre completo del profesor"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formTitle">
            <Form.Label>Título</Form.Label>
            <Form.Control
              type="text"
              placeholder="Título del profesor"
              name="title"
              value={formData.title}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formImgFile">
            <Form.Label>Archivo de Imagen</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleFileChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TeacherModal;
