import logo from './logo.svg';
import './App.css';
import { UserProvider, useUser } from './components/userContext';
import Login from './pages/Login';
import Main from './pages/Main';
import { Routes,Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <UserProvider>
      <Routes>
        <Route path='/' element={<Login/>}/>
        <Route path='/adminpanel' element={<Main/>}/>

      </Routes>
      </UserProvider>
    </div>
  );
}

export default App;
