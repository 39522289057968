import React, { Fragment } from 'react'

function OptionsPanel({setOption}) {
  return (
    <Fragment>
        <div class="list-group w-100">
        <button type="button" class="list-group-item list-group-item-action" onClick={() => setOption('1')}>Cursos</button>
        <button type="button" class="list-group-item list-group-item-action" onClick={() => setOption('2')}>Profesores</button>
        <button type="button" class="list-group-item list-group-item-action" onClick={() => setOption('3')}>Category</button>
        <button type="button" class="list-group-item list-group-item-action"  onClick={() => setOption('4')}>Alumnos</button>
        </div>
    </Fragment>
  )
}

export default OptionsPanel
