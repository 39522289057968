import React, { useState } from 'react';
import { Modal, Button, Form, Toast, ToastContainer } from 'react-bootstrap';
import { useConfig } from '../configContext';

const CourseModuleSessionModalCRUD = ({ show, handleClose, idCourse, idModule, onSessionAdded }) => {
  const config = useConfig();
  const [newSession, setNewSession] = useState({ name: '', number: '' });
  const [file, setFile] = useState(null);
  const [toasts, setToasts] = useState([]);

  const handleAddSessionChange = (e) => {
    const { name, value } = e.target;
    setNewSession({ ...newSession, [name]: value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const addToast = (message, variant) => {
    setToasts((prevToasts) => [
      ...prevToasts,
      { id: new Date().getTime(), message, variant }
    ]);
  };

  const handleAddSession = async () => {
    const sessionName = newSession.name;
    addToast(`Guardando la sesión "${sessionName}", por favor espera...`, 'info');

    const formData = new FormData();
    formData.append('name', newSession.name);
    formData.append('number', newSession.number);
    formData.append('IdCourse', idCourse);
    formData.append('IdModule', idModule);
    if (file) {
      formData.append('file', file);
    }

    try {
      // Obtener la duración del video desde el frontend
      const videoDuration = await getVideoDuration(file);

      // Añadir la duración al FormData
      formData.append('duration', videoDuration);

      const response = await fetch(`${config.apiUrl}/CourseModuleSession/Insert`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        onSessionAdded(data.data); // Actualiza la lista de sesiones en la vista principal
        addToast(`La sesión "${sessionName}" se ha guardado correctamente.`, 'success');
      } else {
        console.error('Failed to add session');
        addToast(`Error al guardar la sesión "${sessionName}".`, 'danger');
      }
    } catch (error) {
      console.error('Error adding session', error);
      addToast(`Error al guardar la sesión "${sessionName}".`, 'danger');
    }
  };

  const getVideoDuration = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.src = URL.createObjectURL(file);
      video.addEventListener('loadedmetadata', () => {
        const duration = video.duration;
        URL.revokeObjectURL(video.src);
        resolve(formatDuration(duration));
      });
      video.addEventListener('error', reject);
    });
  };

  const formatDuration = (duration) => {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    return `${minutes}min ${seconds}seg`;
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static"> {/* Evita que el modal se cierre al hacer clic fuera */}
        <Modal.Header closeButton>
          <Modal.Title>Agregar Sesión</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formSessionName">
              <Form.Label>Nombre de la Sesión</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese el nombre de la sesión"
                name="name"
                value={newSession.name}
                onChange={handleAddSessionChange}
              />
            </Form.Group>
            <Form.Group controlId="formSessionNumber">
              <Form.Label>Número de la Sesión</Form.Label>
              <Form.Control
                type="number"
                placeholder="Ingrese el número de la sesión"
                name="number"
                value={newSession.number}
                onChange={handleAddSessionChange}
              />
            </Form.Group>
            <Form.Group controlId="formFile">
              <Form.Label>Archivo de Video</Form.Label>
              <Form.Control
                type="file"
                accept="video/*"
                onChange={handleFileChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleAddSession}>
            Guardar Sesión
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer 
        position="bottom-end" 
        className="p-3"
      >
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            bg={toast.variant}
            onClose={() => setToasts((prevToasts) => prevToasts.filter((t) => t.id !== toast.id))}
         
          >
            <Toast.Header>
              <strong className="me-auto">Notificación</strong>
            </Toast.Header>
            <Toast.Body>{toast.message}</Toast.Body>
          </Toast>
        ))}
      </ToastContainer>
    </>
  );
};

export default CourseModuleSessionModalCRUD;
