import React, { useState, useEffect } from 'react';
import { Modal, Button, Table, Form } from 'react-bootstrap';
import { useConfig } from '../configContext';

const AddCourseRequirementModal = ({ show, handleClose, idCourse }) => {
  const [requirements, setRequirements] = useState([]);
  const [newRequirement, setNewRequirement] = useState('');
  const [showAddForm, setShowAddForm] = useState(false);
  const config = useConfig();

  const fetchRequirements = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/courseRequirement/${idCourse}`);
      if (response.ok) {
        const data = await response.json();
        setRequirements(data.data || []);
      } else {
        console.error('Failed to fetch requirements');
      }
    } catch (error) {
      console.error('Error fetching requirements', error);
    }
  };


  useEffect(() => {
 fetchRequirements();
    if (idCourse) {
      fetchRequirements();
    }
  }, [idCourse, config.apiUrl]);

  const handleAddRequirement = async () => {
    if (!newRequirement.trim()) {
      alert("La descripción del requerimiento no puede estar vacía");
      return;
    }

    try {
      const response = await fetch(`${config.apiUrl}/courseRequirement`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idCourse, description: newRequirement }),
      });

      if (response.ok) {
        fetchRequirements()
      } else {
        console.error('Failed to add requirement');
      }
    } catch (error) {
      console.error('Error adding requirement', error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Requerimientos del Curso</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Requerimiento</th>
            </tr>
          </thead>
          <tbody>
            {requirements.map((req, index) => (
              <tr key={index}>
                <td>{req.idRequirement}</td>
                <td>{req.description}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {showAddForm && (
          <Form.Group>
            <Form.Label>Nuevo Requerimiento</Form.Label>
            <Form.Control
              type="text"
              placeholder="Descripción del requerimiento"
              value={newRequirement}
              onChange={(e) => setNewRequirement(e.target.value)}
            />
            <Button variant="primary" onClick={handleAddRequirement} className="mt-2">
              Guardar
            </Button>
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!showAddForm && (
          <Button variant="secondary" onClick={() => setShowAddForm(true)}>
            Agregar requerimiento
          </Button>
        )}
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCourseRequirementModal;
