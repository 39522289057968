import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useConfig } from '../configContext';
import CourseModuleModal from './CourseModuleModal';
import AddCourseRequirementModal from './AddCourseRequirementModal'; // Importar el nuevo modal

const CourseModal = ({ show, handleClose, handleSave, course, categories }) => {
  const config = useConfig();

  const [formData, setFormData] = useState({
    id: '',
    name: '',
    price: '',
    description: '',
    idCategory: '',
    idTeacher: '',
    file: null,   // Video file
    imgFile: null, // Image file
    syllabusFile: null, // Nuevo archivo PDF
    discount: '', // Nuevo campo de descuento
    active: true, // Nuevo checkbox para estado activo
    filename:  '',   // Reset video file
    imgFilename: '', // Reset image file
    syllabusFilename: ''// Reset syllabus file
  });

  const [teachers, setTeachers] = useState([]);
  const [showModuleModal, setShowModuleModal] = useState(false);
  const [showRequirementModal, setShowRequirementModal] = useState(false); // Estado para el modal de requerimientos

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/teacher`);
        if (response.ok) {
          const data = await response.json();
          setTeachers(data.data);
        } else {
          console.error('Failed to fetch teachers');
        }
      } catch (error) {
        console.error('Error fetching teachers', error);
      }
    };

    fetchTeachers();
  }, [config.apiUrl]);

  useEffect(() => {
    if (course) {
      setFormData({
        id: course.id || '',
        name: course.name || '',
        price: course.price || '0',
        description: course.description || '',
        idCategory: course.idCategory || '',
        idTeacher: course.idTeacher || '',
        file: null,   // Reset video file
        imgFile: null, // Reset image file
        syllabusFile: null, // Reset syllabus file
        filename: course.fileVideo || '',   // Reset video file
        imgFilename: course.imgFile || '', // Reset image file
        syllabusFilename: course.syllabus || '', // Reset syllabus file
        discount: course.discount || '0', // Establecer descuento si existe
        active: course.active || false // Establecer estado activo si existe
      });
    } else {
      setFormData({
        id: '',
        name: '',
        price: '',
        description: '',
        idCategory: '',
        idTeacher: '',
        file: null,
        imgFile: null,
        syllabusFile: null,
        filename:  '',   // Reset video file
        imgFilename:  '', // Reset image file
        syllabusFilename:  '', // Reset syllabus file
        discount: '',
        active: false
      });
    }
  }, [course]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    setFormData({
      ...formData,
      active: e.target.checked,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      file: e.target.files[0],
    });
  };

  const handleImgFileChange = (e) => {
    setFormData({
      ...formData,
      imgFile: e.target.files[0],
    });
  };

  const handleSyllabusFileChange = (e) => {
    setFormData({
      ...formData,
      syllabusFile: e.target.files[0],
    });
  };

  const handleSubmit = () => {
    const data = new FormData();
    data.append('id',formData.id);
    data.append('name', formData.name);
    data.append('price', formData.price);
    data.append('description', formData.description);
    data.append('idCategory', formData.idCategory);
    data.append('idTeacher', formData.idTeacher);
    data.append('discount', formData.discount); // Añadir descuento
    data.append('active', formData.active); // Añadir estado activo
    data.append('fileVideo', formData.fileVideo);
    data.append('imgFile', formData.imgFile);
    data.append('syllabus', formData.syllabus);

    data.append('filename', formData.filename);
    data.append('imgFilename', formData.imgFilename);
    data.append('syllabusFilename', formData.syllabusFilename);

    if (formData.file) {
      data.append('file', formData.file);
    }
    if (formData.imgFile) {
      data.append('imgFile', formData.imgFile);
    }
    if (formData.syllabusFile) {
      data.append('SyllabusFile', formData.syllabusFile); // Añadir archivo PDF
    }
    console.log('Submitting data:', [...data.entries()]); // Verifica los datos

    handleSave(data);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{course ? 'Editar Curso' : 'Agregar Curso'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre del curso"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formPrice">
              <Form.Label>Precio</Form.Label>
              <Form.Control
                type="number"
                placeholder="Precio del curso"
                name="price"
                value={formData.price}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                type="text"
                placeholder="Descripción del curso"
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formCategory">
              <Form.Label>Categoría</Form.Label>
              <Form.Control
                as="select"
                name="idCategory"
                value={formData.idCategory}
                onChange={handleChange}
              >
                <option value="">Seleccionar Categoría</option>
                {categories.map(category => (
                  <option key={category.idCategory} value={category.idCategory}>
                    {category.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formTeacher">
              <Form.Label>Profesor</Form.Label>
              <Form.Control
                as="select"
                name="idTeacher"
                value={formData.idTeacher}
                onChange={handleChange}
              >
                <option value="">Seleccionar Profesor</option>
                {teachers.map(teacher => (
                  <option key={teacher.idTeacher} value={teacher.idTeacher}>
                    {teacher.fullName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formDiscount">
              <Form.Label>Descuento (%)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Ingrese el porcentaje de descuento"
                name="discount"
                value={formData.discount}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formIsActive">
              <Form.Check
                type="checkbox"
                label="Curso Activo"
                name="active"
                checked={formData.active}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
            <Form.Group controlId="formFile">
              <Form.Label>Video</Form.Label>
              <Form.Control
                type="file"
                accept="video/*"
                onChange={handleFileChange}
              />
            </Form.Group>
            <Form.Group controlId="formImgFile">
              <Form.Label>Imagen</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleImgFileChange}
              />
            </Form.Group>
            <Form.Group controlId="formSyllabusFile">
              <Form.Label>Syllabus</Form.Label>
              <Form.Control
                type="file"
                accept="application/pdf"
                onChange={handleSyllabusFileChange}
              />
            </Form.Group>
          </Form>
          <Button
            variant="secondary"
            onClick={() => setShowModuleModal(true)}
          >
            Ver Módulos
          </Button>
          <Button
            variant="secondary"
            onClick={() => setShowRequirementModal(true)} // Abre el modal de requerimientos
            className="ml-2"
          >
            Ver Requerimientos
          </Button>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
      <CourseModuleModal
        show={showModuleModal}
        handleClose={() => setShowModuleModal(false)}
        idCourse={formData.id}
      />
      <AddCourseRequirementModal
        show={showRequirementModal}
        handleClose={() => setShowRequirementModal(false)}
        idCourse={formData.id}
      />
    </>
  );
};

export default CourseModal;
