import React, { useEffect, useState, Fragment } from 'react';
import { Table, Button } from 'react-bootstrap';
import { useConfig } from '../configContext';
import AddCourseModal from '../course/AddCourseModal';

function Student() {
  const config = useConfig();
  const [students, setStudents] = useState([]);
  const [showAddCourseModal, setShowAddCourseModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/actor/getall`);
        if (response.ok) {
          const data = await response.json();
          setStudents(data.data);
        } else {
          console.error('Failed to fetch students');
        }
      } catch (error) {
        console.error('Error fetching students', error);
      }
    };

    fetchStudents();
  }, [config.apiUrl]);

  const handleAddCourse = (studentId) => {
    setSelectedStudent(studentId);
    setShowAddCourseModal(true);
  };

  const handleCloseAddCourseModal = () => {
    setShowAddCourseModal(false);
    setSelectedStudent(null);
  };

  return (
    <Fragment>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>Nombre Completo</th>
            <th>Número de Documento</th>
            <th>Nombre de Usuario</th>
            <th>Activo</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {students.map((student) => (
            <tr key={student.idActor}>
              <td>{student.name}</td>
              <td>{student.surname}</td>
              <td>{student.fullName}</td>
              <td>{student.numeroDocumento}</td>
              <td>{student.userName}</td>
              <td>{student.isActive === 'True' ? 'Sí' : 'No'}</td>
              <td>
                <Button
                  variant="primary"
                  onClick={() => handleAddCourse(student.idActor)}
                  disabled={student.isActive === 'False'}
                >
                  Agregar Curso
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <AddCourseModal
        show={showAddCourseModal}
        handleClose={handleCloseAddCourseModal}
        idActor={selectedStudent}
        idStudent={selectedStudent} // Incluye el IdStudent aquí
        onCourseAdded={() => console.log('Course added successfully')}
      />
    </Fragment>
  );
}

export default Student;
