import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useConfig } from '../configContext';

const CategoryModal = ({ show, handleClose, categoryToEdit, onCategorySaved }) => {
  const config = useConfig();
  const [category, setCategory] = useState({ name: '', active: true });

  useEffect(() => {
    if (categoryToEdit) {
      setCategory({
        name: categoryToEdit.name,
        active: categoryToEdit.active,
        idCategory: categoryToEdit.idCategory
      });
    } else {
      setCategory({ name: '', active: true,idCategory:0 });
    }
  }, [categoryToEdit]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCategory({
      ...category,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSave = async () => {
    const method = 'POST';
    const url = categoryToEdit
      ? `${config.apiUrl}/category/update`
      : `${config.apiUrl}/category/insert`;

    try {
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(category),
      });

      if (response.ok) {
        const data = await response.json();
        onCategorySaved(data.data); // Actualiza la lista de categorías en la vista principal
        handleClose();
      } else {
        console.error('Failed to save category');
      }
    } catch (error) {
      console.error('Error saving category', error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{categoryToEdit ? 'Editar Categoría' : 'Crear Categoría'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formCategoryName">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese el nombre"
              name="name"
              value={category.name}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formCategoryActive">
            <Form.Check
              type="checkbox"
              label="Activo"
              name="active"
              checked={category.active}
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSave}>
          {categoryToEdit ? 'Guardar Cambios' : 'Crear Categoría'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CategoryModal;
