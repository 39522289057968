import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/login.css';
import { useConfig } from '../components/configContext';

function Login() {
  const navigate = useNavigate();
  const config = useConfig();

  const el = useRef(null);
  const [formData, setFormData] = useState({
    userName: '',
    password: ''
  });
  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const apiUrl = `${config.apiUrl}/admin/validate`; 
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();

      if (data.data == "0") {

        setResponseMessage('Credenciales incorrectas');
      } 
      else
       {
        localStorage.setItem('token', data.data); // Save JWT to local storage
        navigate('/adminpanel'); // Navigate to the dashboard or another page
       }
    } catch (error) {
      setResponseMessage('Error en la conexión con el servidor');
    }
  };

  return (
    <Fragment>
      <div className="container">
        <div className="d-flex justify-content-center align-items-center contenedor">
          <div className='square border border-info-subtle mx-1 shadow row rounded'>
            <div className='col'>
              <div className='text-center mt-5'>
                <h1> NOMBRE </h1>
              </div>

              <div className='text-center container login1 d-flex flex-column justify-content-between mt-4'>
                <form className='form-group' onSubmit={handleSubmit}>
                  <input
                    className="form-control logintxt mt-3"
                    type="text"
                    placeholder="Dirección de correo electronico"
                    name="userName"
                    value={formData.userName}
                    onChange={handleChange}
                  />
                  <input
                    className="form-control logintxt mt-3"
                    type="password"
                    placeholder="Contraseña"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  <div className="d-grid gap-2 mt-3 loginbtn">
                    <button className="btn btn-outline-success" type="submit">Ingresar</button>
                  </div>
                </form>
                {responseMessage && <div className="mt-3">{responseMessage}</div>}
                <div>
                  <a className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover vh-100" href="#">
                    Olvide mi contraseña
                  </a>
                </div>
              </div>
            </div>
  
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Login;
