import React, { useState, useEffect, Fragment } from 'react';
import { useConfig } from '../configContext';
import CourseModal from '../course/CourseModal';

function Courses() {
  const config = useConfig();

  const [courses, setCourses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [categories, setCategories] = useState([]);

  const fetchCourses = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/course/-1/-1`);
      if (response.ok) {
        const data = await response.json();
        setCourses(data.data);
      } else {
        console.error('Failed to fetch courses');
      }
    } catch (error) {
      console.error('Error fetching courses', error);
    }
  };


  useEffect(() => {

    const fetchCategories = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/category`); // Ajusta esta URL según tu API
        if (response.ok) {
          const data = await response.json();
          setCategories(data.data);
        } else {
          console.error('Failed to fetch categories');
        }
      } catch (error) {
        console.error('Error fetching categories', error);
      }
    };

    fetchCourses();
    fetchCategories();
  }, [config.apiUrl]);

  const handleShowModal = (course = null) => {
    setSelectedCourse(course);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCourse(null);
  };

  const handleSaveCourse = async (course) => {
    try {
      console.log('Submitting data:', [...course.entries()]); // Verifica los datos
      const token = localStorage.getItem('token'); 

      // Definir el endpoint para Insertar o Actualizar el curso
      const endpoint = course.get("id") ? '/course/Update' : '/course/Insert';

      // Hacer la petición POST para Insertar o Actualizar
      const response = await fetch(`${config.apiUrl}${endpoint}`, {
        method: 'POST',
        body: course, // Enviar el FormData completo
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${token}` // Autenticación con token
        }
      });

      if (response.ok) {
        const data = await response.json();
        // Si el curso es editado o añadido con éxito, refetch de la lista de cursos
        fetchCourses();
        setShowModal(false); // Cerrar el modal tras el guardado
      } else {
        console.error('Error saving course');
      }
    } catch (error) {
      console.error('Error saving course:', error);
    }
  };


  return (
    <Fragment>
      <button type="button" className="btn btn-primary" onClick={() => handleShowModal()}>Agregar nuevo curso</button>
      <table className="table w-100">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Name</th>
            <th scope="col">Price</th>
            <th scope="col">Description</th>
            <th scope="col">Category</th>
            <th scope="col">Editar</th>
            {
            //}<th scope="col">Eliminar</th>
            }
          </tr>
        </thead>
        <tbody>
          {courses.map((course) => (
            <tr key={course.id}>
              <th scope="row">{course.id}</th>
              <td>{course.name}</td>
              <td>S/{course.price}</td>
              <td>{course.description}</td>
              <td>{course.category}</td>
              <td><button onClick={() => handleShowModal(course)}>Edit</button></td>
           {//<td><button >Delete</button></td>
           }   
            </tr>
          ))}
        </tbody>
      </table>
      <CourseModal
        show={showModal}
        handleClose={handleCloseModal}
        handleSave={handleSaveCourse}
        course={selectedCourse}
        categories={categories}
      />
    </Fragment>
  );
}

export default Courses;
