import React from 'react'
import Courses from '../components/OptionsTabs/Courses'
import Teachers from '../components/OptionsTabs/Teachers'
import Category from '../components/OptionsTabs/Category'
import Student from './OptionsTabs/Student'
function OptionsTab({option}) {
  return (
    <div>
    <div>
      {option === '1' && <Courses />}
      {option === '2' && <Teachers />}
      {option === '3' && <Category />}
      {option === '4' && <Student />}

      {!option && <div>Por favor, selecciona una opción.</div>}
    </div>
    </div>
  )
}

export default OptionsTab
