import React, { useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import { useConfig } from '../configContext';
import CategoryModal from '../category/CategoryModal'; // Asegúrate de la ruta correcta

const Category = () => {
  const config = useConfig();
  const [categories, setCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState(null);

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/category`);
      if (response.ok) {
        const data = await response.json();
        setCategories(data.data);
      } else {
        console.error('Failed to fetch categories');
      }
    } catch (error) {
      console.error('Error fetching categories', error);
    }
  };

  useEffect(() => {

    fetchCategories();
  }, [config.apiUrl]);

  const handleEditClick = (category) => {
    setCategoryToEdit(category);
    setShowModal(true);
  };

  const handleCreateClick = () => {
    setCategoryToEdit(null);
    setShowModal(true);
  };

  const handleCategorySaved = (savedCategory) => {
fetchCategories();
  };

  return (
    <div>
      <Button variant="success" onClick={handleCreateClick} className="mb-3">
        Crear Categoría
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Activo</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <tr key={category.id}>
              <td>{category.name}</td>
              <td>{category.active ? 'Sí' : 'No'}</td>
              <td>
                <Button variant="warning" onClick={() => handleEditClick(category)}>
                  Editar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <CategoryModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        categoryToEdit={categoryToEdit}
        onCategorySaved={handleCategorySaved}
      />
    </div>
  );
};

export default Category;
