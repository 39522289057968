import React, { useState, useEffect } from 'react';
import { Modal, Button, Table, Form } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useConfig } from '../configContext';
import SessionModal from '../course/CourseModuleSesionModal';

const CourseModuleModal = ({ show, handleClose, idCourse }) => {
  const config = useConfig();
  const [modules, setModules] = useState([]);
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);
  const [showAddModuleModal, setShowAddModuleModal] = useState(false);
  const [newModule, setNewModule] = useState({ name: '', number: '' });
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [moduleToUpdate, setModuleToUpdate] = useState(null);

  const fetchModules = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/CourseModule/${idCourse}`);
      if (response.ok) {
        const data = await response.json();
        setModules(data.data);
      } else {
        console.error('Failed to fetch modules');
      }
    } catch (error) {
      console.error('Error fetching modules', error);
    }
  };

  useEffect(() => {
    if (idCourse) {
      fetchModules();
    }
  }, [idCourse, config.apiUrl]);

  const handleOpenSessionModal = (module) => {
    setSelectedModule(module);
    setShowSessionModal(true);
  };

  const handleCloseSessionModal = () => {
    setShowSessionModal(false);
    setSelectedModule(null);
  };

  const handleOpenAddModuleModal = () => {
    setShowAddModuleModal(true);
  };

  const handleCloseAddModuleModal = () => {
    setShowAddModuleModal(false);
  };

  const handleAddModuleChange = (e) => {
    const { name, value } = e.target;
    setNewModule({ ...newModule, [name]: value });
  };

  const handleAddModule = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${config.apiUrl}/CourseModule/insert`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ ...newModule, idCourse }),
      });

      if (response.ok) {
        fetchModules();
        handleCloseAddModuleModal();
      } else {
        console.error('Failed to add module');
      }
    } catch (error) {
      console.error('Error adding module', error);
    }
  };

  const handleOpenUpdateModal = (module) => {
    setModuleToUpdate({ ...module });
    setShowUpdateModal(true);
  };

  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    setModuleToUpdate(null);
  };

  const handleUpdateModuleChange = (e) => {
    const { name, value } = e.target;
    setModuleToUpdate({ ...moduleToUpdate, [name]: value });
  };

  const handleUpdateModule = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${config.apiUrl}/CourseModule/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(moduleToUpdate),
      });

      if (response.ok) {
        fetchModules();
        handleCloseUpdateModal();
      } else {
        console.error('Failed to update module');
      }
    } catch (error) {
      console.error('Error updating module', error);
    }
  };

  const handleDeleteModule = async (idModule) => {
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${config.apiUrl}/CourseModule/delete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ idModule }),
      });

      const result = await response.json();

      if (result.data === 1) {
        toast.success('Módulo eliminado correctamente');
        fetchModules();
      } else if (result.data === -1) {
        toast.error('Debe eliminar primero las sesiones de este módulo');
      } else {
        toast.error('Error al eliminar el módulo');
      }
    } catch (error) {
      console.error('Error deleting module', error);
      toast.error('Error al eliminar el módulo');
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Modules</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Orden</th>
                <th>Nombre</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {modules.map((module) => (
                <tr key={module.idModule}>
                  <td>{module.number}</td>
                  <td>{module.name}</td>
                  <td>
                    <Button
                      variant="primary"
                      onClick={() => handleOpenSessionModal(module)}
                    >
                      Ver Sesiones
                    </Button>{' '}
                    <Button
                      variant="warning"
                      onClick={() => handleOpenUpdateModal(module)}
                    >
                      Actualizar
                    </Button>{' '}
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteModule(module.idModule)}
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button
            variant="success"
            onClick={handleOpenAddModuleModal}
            className="mt-3"
          >
            Agregar Módulo
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para ver sesiones */}
      {selectedModule && (
        <SessionModal
          show={showSessionModal}
          handleClose={handleCloseSessionModal}
          idCourse={idCourse}
          idModule={selectedModule.idModule}
        />
      )}

      {/* Modal para agregar módulo */}
      <Modal show={showAddModuleModal} onHide={handleCloseAddModuleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Módulo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formModuleName">
              <Form.Label>Nombre del Módulo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese el nombre del módulo"
                name="name"
                value={newModule.name}
                onChange={handleAddModuleChange}
              />
            </Form.Group>
            <Form.Group controlId="formModuleNumber">
              <Form.Label>Orden del Módulo</Form.Label>
              <Form.Control
                type="number"
                placeholder="Ingrese el número del módulo"
                name="number"
                value={newModule.number}
                onChange={handleAddModuleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddModuleModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleAddModule}>
            Guardar Módulo
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para actualizar módulo */}
      {moduleToUpdate && (
        <Modal show={showUpdateModal} onHide={handleCloseUpdateModal}>
          <Modal.Header closeButton>
            <Modal.Title>Actualizar Módulo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formUpdateModuleName">
                <Form.Label>Nombre del Módulo</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Actualizar nombre del módulo"
                  name="name"
                  value={moduleToUpdate.name}
                  onChange={handleUpdateModuleChange}
                />
              </Form.Group>
              <Form.Group controlId="formUpdateModuleNumber">
                <Form.Label>Orden del Módulo</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Actualizar número del módulo"
                  name="number"
                  value={moduleToUpdate.number}
                  onChange={handleUpdateModuleChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseUpdateModal}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleUpdateModule}>
              Guardar Cambios
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Toast container for notifications */}
      <ToastContainer />
    </>
  );
};

export default CourseModuleModal;
